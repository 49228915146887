@import "global";


.nav-link {
  color: #3d3d3dd4;
  font-weight: 500;
  text-align: center;
}

.nav-link:hover {
  color: #1f1e1e;

}

.dropdown-toggle::after {
  display: none;
}

.dropdown-toggle {
  text-align: center;
}


.dropdown-menu {
  font-size: 15px;

}

@media (max-width: 991.98px) {
  .dropdown-menu {
    text-align: center;

  }

  .navbar-nav {
    padding-right: 0 !important;
  }
}

@media (max-width: 390px) {

  .logo-name {
    // display: none;
    font-size: 17px;
  }

}