@import "global";

.article-container {
  background-color: white;
  padding: 40px;
  //border-radius: 10px;
  //box-shadow: 0 8px 23px #0000001a;
  margin-top: 30px;
  width: 60%;
}

.article-container-new {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;

  .article {
    width: 60%;
    max-width: 1000px;


    h2 {
      color: #19527d;
      font-weight: 600;
      text-align: center;
      border-bottom: 4px solid;
      padding-bottom: 20px;
      border-image: linear-gradient(to right, $braun, $cream);
      border-image-slice: 1;
      //padding-top: 50px;
      width: 100%;
    }

    h3 {
      color: $braun;
      text-align: center;
      padding-top: 20px;
      font-size: 22px;
      width: 80%;
    }

    h4 {
      color: #5E5E5E;
      font-size: 18px;
    }

    h5 {
      font-size: 17px;
    }

    p {
      padding-top: 30px;
      width: 100%;

      a {
        color: $braun;
        text-decoration: none;
      }


    }
  }

}

.links {
  ul {
    padding-top: 10px;
  }

  h4:hover {
    color: #383636;
  }
}

.icons {

  img {
    transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  img:hover {
    transform: scale(1.15);
  }
}

.about-me {
  width: 80%;
  max-width: 1000px;
}

.about-me-title {
  h3 {
    color: #005d77;
    font-weight: 700;
    font-size: 26px;
  }

}


@media (max-width: 800px) {

  .article-container {
    width: 100%;
    padding: 50px 0;
  }

  .article {
    width: 80%;

    p {
      padding-top: 30px;
    }
  }

  .article-container-new {
    .article {
      width: 80%;

      p {
        padding-top: 30px;
      }

      h3 {
        width: 100%;
      }
    }
  }

  .about-me {
    width: 95%;
  }

  //.about-me-title{
  //  h3{
  //    font-size: 16px;
  //  }
  //
  //}
}