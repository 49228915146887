@import "global";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(251, 251, 251);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  position: relative;
  background: url("../imgs/background.jpg") center/cover no-repeat;
  height: 90vh;
  text-align: center;
  padding-top: 1%;

  .header-title {
    color: rgba(16, 15, 13, 0.72);
    /*color:#7a4d45;*/
    font-size: 8vh;
    z-index: 2;
    font-weight: 600;
    letter-spacing: 3px;
  }

  //.header::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   background-color: rgba(7, 6, 6, 0.25);
  //   z-index: 1;
  // }

  //&::before {
  //  content: "";
  //  position: absolute;
  //  bottom: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 5%; /* Adjust the height of the gradient overlay as needed */
  //  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #F9FBF8 100%);
  //  pointer-events: none; /* This ensures that the gradient overlay does not interfere with the content */
  //  z-index: 3;
  //}

}


.quote {
  position: relative;
  background: url("../imgs/angel2.jpg") center/cover no-repeat;
  height: 60vh;
  text-align: center;
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  font-size: 3vh;
  color: #535050;
  font-weight: 600;


  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 20%;
    /* Adjust the height of the gradient overlay as needed */
    pointer-events: none;
    /* This ensures that the gradient overlay does not interfere with the content */
    z-index: 3;
  }

  &::before {
    top: 0;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #F9FBF8 100%);
  }

  &::after {
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #F9FBF8 100%);
  }
}


.title-with-image-container {
  position: relative;
}

.title {
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 20px 0;
}

.image-text {
  position: relative;
  z-index: 2;
  color: transparent;
  background: linear-gradient(to right, $cream, $braun);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: 1000;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.article-content {
  padding: 0 30px;

  .link {
    color: #008fab;
    text-decoration: none;

    &:hover {
      font-weight: 600;
    }
  }

  h2 {
    font-size: 22px;
    padding: 20px 0;
    text-align: center;
  }
}

.impressum {
  padding: 50px;
}

.impressum-content {
  width: 70%;
  background-color: white;
  padding: 50px;
}

.datenschutz {
  padding: 50px;

  h1 {
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 25px;
    font-weight: bold;
  }

  h2 {
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 22px;
    font-weight: 700;
  }

  h3 {
    padding: 10px 0;
    font-size: 20px;
    font-weight: bold;
  }

  h4 {
    padding: 10px 0;
    font-size: 20px;
  }
}

.profil-pic {
  height: 80vh;
  border-radius: 10px;
}


.footer-pic {
  position: relative;
  background: url("../imgs/blau.jpg") center/cover no-repeat;
  height: 40vh;
  width: 60%;
  text-align: center;
  padding-top: 1%;
}

.footer-pic::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgb(251, 251, 251) 100%);
  pointer-events: none;
  /* This ensures that the gradient overlay does not interfere with the content */
  // z-index: 0;
}

.footer {
  height: 100%;
  padding: 30px 0;
  background-color: rgb(33, 37, 41);
  color: white;


  a {
    text-decoration: none;
    color: #8bb5cf;
  }

  a:hover {
    font-weight: 500;
  }

  div {
    padding: 7px 0;
  }

}

@media (max-width: 870px) {



  .header {
    height: 45vh;
  }

  .impressum {
    padding: 0px;
  }

  .impressum-content {
    width: 90%;
    padding: 20px;

    h1 {
      font-size: 22px;
    }
  }

  .datenschutz {
    padding: 0px;
  }

  .profil-pic {
    height: auto;
    width: 90vw;
  }

}

@media (max-width: 1137px) {
  .footer-pic {
    height: 30vh;
    width: 100%;
  }

}