@import "global";

.newsletter-pic {
  position: relative;
  background: url("../imgs/dance.jpg") center/cover no-repeat;
  height: 400px;
  width: 40%;
  text-align: center;

}

.newsletter-bg {
  position: relative;
  background: url("../imgs/bg-quote3.jpg") center/cover no-repeat;
  //height: 30vh;
  text-align: center;
  display: flex;
  // margin: 60px 0;
  align-items: center;
  justify-content: center;
  font-size: 3vh;
  //color: #008fab;


  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 10%;
    /* Adjust the height of the gradient overlay as needed */
    pointer-events: none;
    /* This ensures that the gradient overlay does not interfere with the content */
    z-index: 3;
  }

  &::before {
    top: 0;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #F9FBF8 100%);
  }

  &::after {
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #F9FBF8 100%);
  }
}

.contact-bg {
  position: relative;
  background: url("../imgs/dna.jpg") center/cover no-repeat;
  height: 30vh;
  text-align: center;
  display: flex;
  //margin: 60px 0;
  align-items: center;
  justify-content: center;
  font-size: 3vh;
  color: #7a4d45;


  //&::before,
  //&::after {
  //  content: "";
  //  position: absolute;
  //  left: 0;
  //  width: 100%;
  //  height: 10%; /* Adjust the height of the gradient overlay as needed */
  //  pointer-events: none; /* This ensures that the gradient overlay does not interfere with the content */
  //  z-index: 3;
  //}
  //
  //&::before {
  //  top: 0;
  //  background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #F9FBF8 100%);
  //}
  //
  //&::after {
  //  bottom: 0;
  //  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #F9FBF8 100%);
  //}
}

//input :

//$primary: #11998e;
//$secondary: #38ef7d;

$white: #ffffff;
$gray: #9b9b9b;

.form__group {
  position: relative;
  padding: 15px 0 0;
  // margin-top: 10px;
  width: 50%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: 0;
  font-size: 1.3rem;
  color: $braun;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown~.form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $gray;
}

.form__field:focus {
  ~.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $braun;
    font-weight: 700;
  }

  padding-bottom: 6px;
  font-weight: 500;
  border-width: 3px;
  border-image: linear-gradient(to right, $braun, $cream);
  border-image-slice: 1;
}

/* reset input */
.form__field {

  &:required,
  &:invalid {
    box-shadow: none;
  }
}

.textarea {
  font-family: inherit;
  width: 100%;
  border: 2px solid $gray;
  outline: 0;
  font-size: 1.3rem;
  color: $white;
  padding: 7px 4px;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: $gray;
    padding-left: 2px;
  }

  &:placeholder-shown~.form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;

  }
}

.textarea:focus {
  ~.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $braun;
    font-weight: 700;
  }

  padding-bottom: 6px;
  font-weight: 400;
  border-width: 3px;
  border-image: linear-gradient(to right, $braun, $cream);
  border-image-slice: 1;
}

/* reset input */
.form__field {

  &:required,
  &:invalid {
    box-shadow: none;
  }
}

.newsletter-unsubscribe {
  font-size: 12px;
  margin-top: 20px;
  width: 60%;
}

@media (max-width: 600px) {

  .form__group {
    width: 70%;
  }

}

@media (max-width: 870px) {

  .newsletter-subtitle {
    width: 80%;
  }

  .newsletter-unsubscribe {
    width: 80%;
  }

}