//$cream: rgb(247 192 168);
//$braun: rgb(82, 173, 147);


//$cream: rgb(247 192 168);
//$braun: rgb(145 100 92);

$cream:rgb(38, 67, 154);
$braun:#008fab;
$gold: #afaf56;

//rgb(9, 174, 206) #06adcd #008fab